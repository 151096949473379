import React, {Component} from 'react'
import './InfoModal.css'

class InfoModal extends Component {

  render() {
    const modalState = this.props.showModal ? 'visible' : 'hidden';

    return (
      <div className="overlay InfoModal" modal-state={modalState}>
        <div className="content">
          {/*<slot></slot>*/}
          <div className="close-icon">
            <game-icon icon="close" onClick={this.props.closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </game-icon>
          </div>
          <h3 style={{textAlign: "center"}}>HOW TO PLAY</h3>
          <p>Don't Wordle is just like <a href="https://www.nytimes.com/games/wordle/index.html" target="_blank">Wordle</a> but the opposite.</p>
          <p>In Wordle, the goal is to guess a particular 5 letter word in 6 or fewer tries. In Don't Wordle, the goal is to NOT guess the word.</p>
          <p>The catch is that your word attempts must respect the information you have learned in previous guesses.</p>
          <ol>
            <li>🟩 - Once you place a letter correctly, then future guesses must contain that same letter in the same position</li>
            <li>🟨 - Once you place a correct letter in an incorrect location, then future guesses must contain that letter in a different location</li>
            <li>⬜ - Once letters have been guessed and eliminated, then you can no longer use them</li>
          </ol>
          <p>The number of valid words remaining appears at the top, and it will shrink with each guess. If the number gets too low, you can use one of your "UNDOs" to reverse course.</p>
          <p>Be careful! If you accidentally enter the Wordle word, the game is over even if you have some UNDOs remaining.</p>
        </div>
      </div>
    );
  }
}
export default InfoModal;

