import React, {Component} from 'react'
import './Toast.css'

class Toast extends Component {

  constructor() {
      super();
      this.state = {
          showToast: false,
          toastState: 'empty'
      }
      this.resetToastState = this.resetToastState.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
      const newErrorReceived = !prevProps.errorMessage && this.props.errorMessage;
      if(newErrorReceived) {
          this.setState({
              showToast: true
          });
          setTimeout(() => {
              this.setState({
                  toastState: 'fade'
              })
          }, 0)
      }

      const errorNoLongerExists = prevProps.errorMessage && !this.props.errorMessage;
      if(errorNoLongerExists) {
          this.setState({
              showToast: false,
              toastState: 'empty'
          })
      }

      const gameIsNowTempEliminated = prevProps.gameResult === 'IN PROGRESS' && this.props.gameResult === 'TEMPORARILY ELIMINATED';
      if(gameIsNowTempEliminated) {
          this.setState({
              showToast: true
          });
          setTimeout(() => {
              this.setState({
                  toastState: 'fade'
              })
          }, 100)
      }

      const gameIsNoLongerTempEliminated = prevProps.gameResult === 'TEMPORARILY ELIMINATED' && this.props.gameResult === 'IN PROGRESS';
      if(gameIsNoLongerTempEliminated) {
          this.setState({
              showToast: false,
              toastState: 'empty'
          });
      }
  }

  resetToastState() {
      this.setState({
          showToast: false,
          toastState: 'empty'
      })
  }

  render() {
    const showToast = this.state.showToast;
    const toastClass = this.state.toastState === 'fade' ? 'toast fade' : 'toast';
    const errorMessage = this.props.errorMessage;
    const validWordRemainingCount = this.props.validWordsRemaining.length;
    const guessesRemaining = this.props.guesses.length - this.props.currentWordIndex;
    const tempEliminatedMessage = `There ${validWordRemainingCount === 1 ? 'is' : 'are'} only ${validWordRemainingCount} valid word${validWordRemainingCount === 1 ? '' : 's'} remaining but you have ${guessesRemaining} guess${guessesRemaining === 1 ? '' : 'es'} to go. You must use one of your Undos.`
    const toastText = this.props.gameResult === 'TEMPORARILY ELIMINATED' ? 
      tempEliminatedMessage : this.props.errorMessage ?
      errorMessage : null;
    const toastPurpose = this.props.gameResult === 'TEMPORARILY ELIMINATED' ?
      'tempeliminated' : this.props.errorMessage ?
      'error' : null;

    return (
      <div className="toaster" id="game-toaster" onTransitionEnd={this.resetToastState}>
          {
              showToast && (
                  <game-toast>
                      <div className={toastClass} toastpurpose={toastPurpose}>{toastText}</div>
                  </game-toast>
              )
          }
      </div>
    );
  }
}

export default Toast;






