import React, {Component} from 'react'
import './App.css';
import GameBoard from './components/GameBoard'
import Keyboard from './components/Keyboard'
import Header from './components/Header'
import CheatModal from './components/CheatModal'
import GameOverModal from './components/GameOverModal'
import InfoModal from './components/InfoModal'
import UndoHistoryModal from './components/UndoHistoryModal'
import SettingsModal from './components/SettingsModal'
import GameOverMessage from './components/GameOverMessage'
import Toast from './components/Toast'
import { wordlist } from './wordlist'
import { wordlist2 } from './wordlist2'

class App extends Component {
  constructor() {
    super()
    this.state = {
      guesses: [
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        },
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        },
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        },
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        },
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        },
        {
          word: '',
          wordResults: [],
          submitted: false,
          isProcessing: false,
          isSuccessWord: false,
          rowIsDisqualified: false,
          numberOfValidWordsRemaining: null
        }
      ],
      currentWordIndex: 0,
      currentWordIsLegalPlay: true,
      gameOver: false,
      showError: false,
      errorMessage: '',
      puzzleNumber: this.getPuzzleNumber(),
      targetWord: wordlist2[this.getPuzzleNumber() - 1],/*wordlist2[Math.floor(Math.random() * wordlist2.length)].toUpperCase()*/
      keyboardStatus: {},
      disableKeyboard: false,
      validWordsRemaining: wordlist,
      cheatModalWordsRemaining: [],
      showCheatModal: false,
      shouldShowStatisticsModal: false,
      shouldShowInfoModal: false,
      shouldShowUndoHistoryModal: false,
      shouldShowSettingsModal: false,
      hardModeEnabled: Boolean(window.localStorage.getItem('hardModeEnabled') === 'true'),
      undosRemaining: Boolean(window.localStorage.getItem('hardModeEnabled') === 'true') ? 2 : 5,
      undoHistory: [],
      gameResult: 'IN PROGRESS', //IN PROGRESS, WORDLE, TEMPORARILY ELIMINATED, ELIMINATED, SURVIVED,
      isSyncingFromLocalStorage: false,
      disableToggleHardMode: false
    }
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.getWordResults = this.getWordResults.bind(this);
    this.getCurrentWordErrorMessage = this.getCurrentWordErrorMessage.bind(this);
    this.handleOnScreenKeyBoardClick = this.handleOnScreenKeyBoardClick.bind(this);
    this.handleWordFinishedAnimating = this.handleWordFinishedAnimating.bind(this);
    this.getFinalScoreStats = this.getFinalScoreStats.bind(this);
    this.showCheatModal = this.showCheatModal.bind(this);
    this.hideCheatModal = this.hideCheatModal.bind(this);
    this.showStatisticsModal = this.showStatisticsModal.bind(this);
    this.hideStatisticsModal = this.hideStatisticsModal.bind(this);
    this.showInfoModal = this.showInfoModal.bind(this);
    this.hideInfoModal = this.hideInfoModal.bind(this);
    this.showUndoHistoryModal = this.showUndoHistoryModal.bind(this);
    this.hideUndoHistoryModal = this.hideUndoHistoryModal.bind(this);
    this.showSettingsModal = this.showSettingsModal.bind(this);
    this.hideSettingsModal = this.hideSettingsModal.bind(this);
    this.undoWord = this.undoWord.bind(this);
    this.persistToLocalStorage = this.persistToLocalStorage.bind(this);
    this.setStateFromLocalStorage = this.setStateFromLocalStorage.bind(this);
    this.handleFinishSyncingFromLocalStorage = this.handleFinishSyncingFromLocalStorage.bind(this);
    this.toggleHardMode = this.toggleHardMode.bind(this);
    this.selectRandomStartingWord = this.selectRandomStartingWord.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    this.setStateFromLocalStorage();
  }

  getPuzzleNumber() {
    const dontWordleInceptionDate = new Date('05/25/2022');
    const today = new Date();
    const daysSinceDontWordleInception = Math.floor((today - dontWordleInceptionDate)/(1000*60*60*24));
    return daysSinceDontWordleInception + 1;
  }

  persistToLocalStorage() {
    const {
      guesses,
      undosRemaining,
      undoHistory,
      puzzleNumber,
      gameResult,
      hardModeEnabled
    } = this.state;
    window.localStorage.setItem('guesses', JSON.stringify(guesses));
    window.localStorage.setItem('undosRemaining', undosRemaining);
    window.localStorage.setItem('undoHistory', JSON.stringify(undoHistory));
    window.localStorage.setItem('lastAttemptedPuzzle', puzzleNumber);

    /*
      currentWinStreak: 0,
      maxStreak: 0,
      totalPlayed: 0,
      totalWordles: 0,
      totalSurvivals: 0,
      totalEliminations: 0,
      lastPuzzlePlayed: '',


    */
    const gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';
    const gameStats = JSON.parse(window.localStorage.getItem('gameStats')) || {
      currentWinStreak: 0,
      maxStreak: 0,
      totalPlayed: 0,
      totalWordles: 0,
      totalSurvivals: 0,
      totalEliminations: 0,
      lastPuzzlePlayed: null
    };
    const newStatsToRecord = gameIsOver && puzzleNumber !== gameStats.lastPuzzlePlayed;
    if(newStatsToRecord) {
      if(gameResult === 'WORDLE') {
        gameStats.currentWinStreak = 0;
        gameStats.totalWordles++;
      }
      if(gameResult === 'ELIMINATED') {
        gameStats.currentWinStreak = 0;
        gameStats.totalEliminations++;
      }
      if(gameResult === 'SURVIVED') {
        gameStats.currentWinStreak++;
        if(gameStats.currentWinStreak > gameStats.maxStreak) {
          gameStats.maxStreak++;
        }
        gameStats.totalSurvivals++;
      }
      gameStats.totalPlayed++;
      gameStats.lastPuzzlePlayed = puzzleNumber;
      window.localStorage.setItem('gameStats', JSON.stringify(gameStats));
      try {
        window.gtag('event', 'finishedGame', {
          puzzleNumber: puzzleNumber,
          gameResult: gameResult,
          hardModeEnabled: hardModeEnabled,
          undosUsed: undoHistory && undoHistory.length,
          validWordRemainingCount: gameResult === 'SURVIVED' ? guesses[5].numberOfValidWordsRemaining : null,
          guesses: JSON.stringify(guesses),
          winStreak: gameStats.currentWinStreak,
          maxStreak: gameStats.maxStreak,
          totalPlayed: gameStats.totalPlayed,
          totalWordles: gameStats.totalWordles,
          totalSurvivals: gameStats.totalSurvivals,
          totalEliminations: gameStats.totalEliminations
        });
      } catch(e) {
        //nothing for now
      }
    }

  }

  setStateFromLocalStorage() {
    const guesses = JSON.parse(window.localStorage.getItem('guesses'));
    const undosRemaining = window.localStorage.getItem('undosRemaining');
    const undoHistory = JSON.parse(window.localStorage.getItem('undoHistory'));
    const lastAttemptedPuzzle = window.localStorage.getItem('lastAttemptedPuzzle');
    const puzzleInState = this.state.puzzleNumber;
    const userHasAlreadyStartedPuzzle = lastAttemptedPuzzle && (Number(lastAttemptedPuzzle) === puzzleInState);
    const userHasNeverPlayed = !lastAttemptedPuzzle;

    if(userHasNeverPlayed) {
      return this.setState({
        shouldShowInfoModal: true
      });
    }


    if(userHasAlreadyStartedPuzzle) {
      if(guesses && undosRemaining && undoHistory) {
        this.setState({
          guesses,
          undosRemaining: Number(undosRemaining),
          undoHistory,
          isSyncingFromLocalStorage: true,
          disableToggleHardMode: true
        });
      }
    }
  }

  toggleHardMode() {
    const disableToggleHardMode = this.state.disableToggleHardMode;
    if(disableToggleHardMode) {
      return this.setState({
        showError: true,
        errorMessage: 'You cannot enable hard mode once a round has started'
      })
    }
    const hardModeEnabled = !this.state.hardModeEnabled;
    //only true b/c disableToggleHardMode check is above
    const gameHasNotStarted = this.state.gameResult === 'IN PROGRESS';
    let undosRemaining = this.state.undosRemaining;
    if(gameHasNotStarted) {
      undosRemaining = hardModeEnabled ? 2 : 5
    }


    window.localStorage.setItem('hardModeEnabled', hardModeEnabled);
    this.setState({
      hardModeEnabled,
      undosRemaining
    })
  }

  getCurrentWordErrorMessage(currentWord, currentWordIndex) {
    if(currentWordIndex === 0) {
      return null;
    }

    const targetWord = this.state.targetWord;
    const previousWord = this.state.guesses[currentWordIndex - 1].word;
    const previousWordResults = this.state.guesses[currentWordIndex - 1].wordResults;

    //enforce re-using greens
    for(var i = 0; i < previousWord.length; i++) {
      const previousWordLetter = previousWord[i];
      const positionWasHitInPriorWord = previousWordResults[i] === 'HIT';
      const reusedHitLetter = currentWord[i] === previousWordLetter;
      if(positionWasHitInPriorWord && !reusedHitLetter) {
        return `Must reuse an ${previousWordLetter} as letter ${i + 1}`
      }
    }

    //don't allow re-using letters that have already been used and were complete misses
    //don't allow re-guessing letters in positions that have been eliminated
    const successLetters = {}
    const completeWhiffs = {}
    const allFailedCoordinates = {}

    for(var i = 0; i < targetWord.length; i++) {
      const successLetter = targetWord[i];
      successLetters[successLetter] = true;
    }
    for(var i = 0; i < currentWordIndex; i ++) {
      const submittedWord = this.state.guesses[i].word
      const submittedWordResults = this.state.guesses[i].wordResults;
      for(var j = 0; j < submittedWord.length; j++) {
        const attemptedLetter = submittedWord[j];
        const attemptedLetterResult = submittedWordResults[j];
        //letter does not appear in target word at all; guaranteed never even a partial miss
        if(!successLetters[attemptedLetter]) {
          completeWhiffs[attemptedLetter] = true;
        }
        const attemptedLetterWasInWrongPosition = attemptedLetterResult === 'PARTIAL' || attemptedLetterResult === 'MISS';
        const attemptedPosition = j;
        const coordinate = `${attemptedLetter}${attemptedPosition}`;
        if(attemptedLetterWasInWrongPosition) {
          allFailedCoordinates[coordinate] = true;
        }
      }
    }
    for(var i = 0; i < currentWord.length; i++) {
      const currentWordLetter = currentWord[i];
      const currentWordLetterWasCompleteWhiff = Boolean(completeWhiffs[currentWordLetter])
      if(currentWordLetterWasCompleteWhiff) {
        return `You have already eliminated the letter ${currentWordLetter}`
      }
    }
    for(var i = 0; i < currentWord.length; i++) {
      const currentWordLetter = currentWord[i];
      const attemptedLetterPosition = i;
      const coordinate = `${currentWordLetter}${attemptedLetterPosition}`
      const currentWordLetterWasAlreadyFailedAttemptInPosition = Boolean(allFailedCoordinates[coordinate])
      if(currentWordLetterWasAlreadyFailedAttemptInPosition) {
        return `You have already guessed the letter ${currentWordLetter} in position ${attemptedLetterPosition + 1}`
      }
    }

    //enforce re-using yellow+green letters at least as many times as in prior word
    const previousWordMatchLetterCounts = {}
    for(var i = 0; i < previousWord.length; i++) {
      const previousWordLetter = previousWord[i];
      const positionWasHitInPriorWord = previousWordResults[i] === 'HIT';
      const positionWasPartialInPriorWord = previousWordResults[i] === 'PARTIAL';
      if(positionWasHitInPriorWord || positionWasPartialInPriorWord) {
        previousWordMatchLetterCounts[previousWordLetter] = previousWordMatchLetterCounts[previousWordLetter] ? previousWordMatchLetterCounts[previousWordLetter] + 1 : 1
      }
    }
    const currentWordLetterCounts = {}
    for(var i = 0; i < currentWord.length; i++) {
      const currentWordLetter = currentWord[i];
      currentWordLetterCounts[currentWordLetter] = currentWordLetterCounts[currentWordLetter] ? currentWordLetterCounts[currentWordLetter] + 1 : 1
    }
    for (var letter in previousWordMatchLetterCounts) {
      const letterCountInPreviousWord = previousWordMatchLetterCounts[letter];
      const letterCountInCurrentWord = currentWordLetterCounts[letter] || 0;
      if(letterCountInPreviousWord > letterCountInCurrentWord) {
        return `You must use the letter ${letter} at least ${letterCountInPreviousWord} times`
      }
    }

    //it's possible you made a multiple letter guess and had a combo of HITs/PARTIALs and MISSes
    //you can't keep making double letter guesses if you already know the max times the letter appears
    const maxAllowableGuessesByLetter = {};
    for(var i = 0; i < currentWordIndex; i++) {
      const submittedWord = this.state.guesses[i].word
      const submittedWordResults = this.state.guesses[i].wordResults;
      const successCountByLetter = {};
      const missedLetters = {}
      for(var j = 0; j < submittedWord.length; j++) {
        const attemptedLetter = submittedWord[j];
        const attemptedLetterResult = submittedWordResults[j];
        const letterWasAtLeastPartial = attemptedLetterResult === 'HIT' || attemptedLetterResult === 'PARTIAL';
        if(letterWasAtLeastPartial) {
          successCountByLetter[attemptedLetter] = successCountByLetter[attemptedLetter] ? successCountByLetter[attemptedLetter] + 1 : 1;
        } else {
          missedLetters[attemptedLetter] = true;
        }
      }
      for(var letter in successCountByLetter) {
        const letterSuccesses = successCountByLetter[letter];
        const letterWasMissedAtLeastOnce = missedLetters[letter]
        if(letterWasMissedAtLeastOnce) {
          maxAllowableGuessesByLetter[letter] = letterSuccesses;
        }
      }
    }
    for(var letter in currentWordLetterCounts) {
      const letterCountInCurrentWord = currentWordLetterCounts[letter];
      const maxAllowableGuesses = maxAllowableGuessesByLetter[letter];
      if(maxAllowableGuesses && (letterCountInCurrentWord > maxAllowableGuesses)) {
        return `You must use the letter ${letter} exactly ${maxAllowableGuesses} time${maxAllowableGuesses === 1 ? '' : 's'}`
      }
    }

  }


  getWordResults(currentWord, targetWord) {
    var currentWord = currentWord.toUpperCase();
    var targetWord = targetWord.toUpperCase();
    const wordResults = ['MISS','MISS','MISS','MISS','MISS'];
    const currentWordLetterCounts = {};
    const targetWordLetterCounts = {};

    currentWord.split('').forEach(letter => {
      currentWordLetterCounts[letter] = currentWordLetterCounts[letter] ? currentWordLetterCounts[letter] + 1 : 1
    });

    targetWord.split('').forEach(letter => {
      targetWordLetterCounts[letter] = targetWordLetterCounts[letter] ? targetWordLetterCounts[letter] + 1 : 1
    });

    //this takes care of exact matches
    currentWord.split('').forEach((letter, index) => {
      const letterIsExactMatch = letter === targetWord[index];
      if(letterIsExactMatch) {
        wordResults[index] = 'HIT';
        //for each exact match we need to reduce the number of potential yellow squares we'd show
        targetWordLetterCounts[letter] -= 1;
      }
    });

    //this takes care of partial matches
    currentWord.split('').forEach((letter, index) => {
      //after accounting for all the HITs, check if the letter is still appearing
      const letterIsExactMatch = letter === targetWord[index];
      const letterIsPartialMatch = !letterIsExactMatch && targetWordLetterCounts[letter];
      if(letterIsPartialMatch) {
        wordResults[index] = 'PARTIAL';
        //for each partial match we need to reduce the number of potential yellow squares we'd show
        targetWordLetterCounts[letter] -= 1;
      }
    });
    return wordResults;
  }

  getUpdatedKeyboardStatus(guesses) {
    let keyboardStatus = {};
    for(var i = 0; i < guesses.length; i++) {
      const guess = guesses[i];
      const resultsByLetter = {}
      for(var j = 0; j < guess.word.length; j++) {
        const attemptedLetter = guess.word[j];
        const letterResult = guess.wordResults[j];
        if(!resultsByLetter[attemptedLetter]) {
          resultsByLetter[attemptedLetter] = letterResult;
        }
        //if any letter was a partial, keyboard needs to show partial
        if(letterResult === 'PARTIAL') {
          resultsByLetter[attemptedLetter] = 'PARTIAL';
        }
        //if any letter was gray but now discover a hit, then green overrides
        if(resultsByLetter[attemptedLetter] === 'MISS' && letterResult === 'HIT') {
          resultsByLetter[attemptedLetter] = 'HIT';
        }
      }
      for(var letter in resultsByLetter) {
        keyboardStatus[letter] = resultsByLetter[letter];
      }
    }

    return keyboardStatus;
  }

  handleKey(key) {
    const disableKeyboard = this.state.disableKeyboard;
    const isSyncingFromLocalStorage = this.state.isSyncingFromLocalStorage;
    const selectRandomStartingWord = key === 'Select Random Word';

    if(isSyncingFromLocalStorage) return;
    if(disableKeyboard && !selectRandomStartingWord) return;
    const alreadyWordled = this.state.gameResult === 'WORDLE';
    const alreadySurvived = this.state.gameResult === 'SURVIVED';
    const alreadyEliminated = this.state.gameResult === 'ELIMINATED';
    if(alreadyWordled || alreadySurvived || alreadyEliminated) {
      return;
    }
    const isInTempEliminatedState = this.state.gameResult === 'TEMPORARILY ELIMINATED';
    if(isInTempEliminatedState) {
      return this.setState({
        showError: true,
        errorMessage: 'Your only option is to Undo'
      })
    }
    const guesses = JSON.parse(JSON.stringify(this.state.guesses))
    const currentWordIndex = this.state.currentWordIndex;
    const currentGuess = guesses[currentWordIndex];
    const targetWord = this.state.targetWord;
    const currentWordIsEmpty = currentGuess.word.length === 0;
    const currentWordIsFull = currentGuess.word.length === 5;

    //if backspace, then delete letter if there is one
    if(key === 'Backspace' && !currentWordIsEmpty) {
      guesses[currentWordIndex].word = guesses[currentWordIndex].word.slice(0,guesses[currentWordIndex].word.length - 1);
      return this.setState({
        guesses,
        showError: false,
        errorMessage: ''
      })
    } 
    //if enter, then try proessing word, probably a lot of stuff
    if((selectRandomStartingWord || key === 'Enter') && currentWordIsFull) {
      const wordIsOnList = wordlist.includes(currentGuess.word);
      //if word is invalid then error
      if(!wordIsOnList) {
        return this.setState({
          showError: true,
          errorMessage: 'Word is not valid'
        })
      }

      const illegalPlayErrorMessage = this.getCurrentWordErrorMessage(currentGuess.word, currentWordIndex);
      if(illegalPlayErrorMessage) {
        return this.setState({
          showError: true,
          errorMessage: illegalPlayErrorMessage
        })
      }
      //if word violates game rules then error
      //if word is valid, then evaluate and add colors
      guesses[currentWordIndex].submitted = true;
      guesses[currentWordIndex].isProcessing = true;
      guesses[currentWordIndex].isSuccessWord = currentGuess.word === targetWord;
      const wordResults = this.getWordResults(currentGuess.word, targetWord);
      guesses[currentWordIndex].wordResults = wordResults;
      return this.setState({
        guesses,
        showError: false,
        errorMessage: '',
        disableKeyboard: true
      })
    }

    //user types a letter
    if(/^[a-zA-Z]{1}$/.test(key) && !currentWordIsFull) {
      guesses[currentWordIndex].word += key.toUpperCase();
      return this.setState({
        guesses,
        showError: false,
        errorMessage: ''
      })
    }
  }

  handleKeyDown(e) {
    const key = e.key;
    if(e.key === 'Escape') {
      return this.setState({
        showCheatModal: false
      })
    }
    if(e.key) {
      this.handleKey(key);
    }
  }

  handleOnScreenKeyBoardClick(e, option) {
    document.activeElement.blur();
    let key = e.target.outerText;
    if(key === 'ENTER') {
      key = 'Enter';
    }
    if(option === 'DEL') {
      key = 'Backspace'
    }
    this.handleKey(key);
  }

  calculateValidWordsRemaining(wordIndex) {
    let validWordsRemaining = [];
    for(var i = 0; i < wordlist.length; i++) {
      const wordToTest = wordlist[i].toUpperCase();
      const wordToTestHasError = this.getCurrentWordErrorMessage(wordToTest, wordIndex);
      if(!wordToTestHasError) {
        validWordsRemaining.push(wordToTest);
      }
    }
    return validWordsRemaining;
  }

  //this gets called each time a letter finishes syncing from local storage
  //only need to call handleWordFinishedAnimating once
  handleFinishSyncingFromLocalStorage(wordIndex, letterIndex) {
    let maxSubmittedWordIndex = null;
    //dumb to hardcode but whatever
    let maxLetterIndex = 4;
    for(var i = 0; i < this.state.guesses.length; i++) {
      const wordIsSubmitted = this.state.guesses[i].submitted;
      if(wordIsSubmitted) {
        maxSubmittedWordIndex = i;
      }
    }
    if(maxSubmittedWordIndex === null) {
      this.setState({
        isSyncingFromLocalStorage: false
      })
    } else if (wordIndex === maxSubmittedWordIndex && letterIndex === maxLetterIndex) {
      this.handleWordFinishedAnimating(wordIndex);
    }
  }

  handleWordFinishedAnimating(wordIndex) {
    const guesses = JSON.parse(JSON.stringify(this.state.guesses));
    guesses[wordIndex].isProcessing = false;
    const keyboardStatus = this.getUpdatedKeyboardStatus(guesses);
    let validWordsRemaining = this.calculateValidWordsRemaining(wordIndex + 1);
    guesses[wordIndex].numberOfValidWordsRemaining = validWordsRemaining.length;


    let gameResult = 'IN PROGRESS';
    const wordleTriggered = guesses[wordIndex].word === this.state.targetWord;
    const guessesRemaining = guesses.length - wordIndex - 1;
    const notEnoughWordsRemaining = guessesRemaining >= validWordsRemaining.length;
    let undosRemaining = this.state.undosRemaining;
    const eliminated = notEnoughWordsRemaining && undosRemaining === 0;
    const tempEliminated = notEnoughWordsRemaining && undosRemaining > 0;
    const survived = (wordIndex + 1 === guesses.length) && !wordleTriggered;
    gameResult = survived ? 'SURVIVED' :
        wordleTriggered ? 'WORDLE' :
        eliminated ? 'ELIMINATED' :
        tempEliminated ? 'TEMPORARILY ELIMINATED' :
        'IN PROGRESS';
    if(wordleTriggered || eliminated) {
      validWordsRemaining = wordleTriggered ? [] : validWordsRemaining;
      for(var i = wordIndex + 1; i < guesses.length; i++) {
        guesses[i].rowIsDisqualified = true;
      }
    }
    if(wordleTriggered) {
      undosRemaining = 0;
      //stupid that we do the manual override
      guesses[wordIndex].numberOfValidWordsRemaining = 0;
    }

    return this.setState({
      guesses,
      keyboardStatus,
      currentWordIndex: wordIndex + 1,
      disableKeyboard: false,
      validWordsRemaining,
      gameResult,
      undosRemaining,
      isSyncingFromLocalStorage: false,
      disableToggleHardMode: gameResult === 'IN PROGRESS' || gameResult === 'TEMPORARILY ELIMINATED'
    }, this.persistToLocalStorage);
  }

  getFinalScoreStats() {
    const userSurvived = this.state.gameResult === 'SURVIVED';
    const { keyboardStatus, guesses, undoHistory } = this.state;
    let unusedLetterCount = null;
    let greenTiles = null;
    if(userSurvived) {
      unusedLetterCount = 0;
      greenTiles = 0;
      for(var i = 0; i < 26; i++) {
        const letter = String.fromCharCode(65 + i);
        const letterIsUnused = !keyboardStatus[letter];
        if(letterIsUnused) {
          unusedLetterCount++;
        }
      }
      for(var j = 0; j < guesses.length; j++) {
        const guess = guesses[j];
        for(var k = 0; k < guess.wordResults.length; k++) {
          const letterResult = guess.wordResults[k];
          if(letterResult === 'HIT') {
            greenTiles++;
          }
        }
      }
    }
    return {
      unusedLetterCount,
      greenTiles
    }
  }

  showCheatModal(wordIndex) {
    const wordIndexAfterRow = wordIndex + 1;
    const validWordsRemaining = this.calculateValidWordsRemaining(wordIndexAfterRow);
    const targetWord = this.state.targetWord;
    let cheatModalWordsRemaining = [];
    for(var i = 0; i < validWordsRemaining.length; i++) {
      const wordRemaining = validWordsRemaining[i];
      const wordResults = this.getWordResults(wordRemaining, targetWord);
      cheatModalWordsRemaining.push({
        word: wordRemaining,
        wordResults: wordResults
      })
    }

    this.setState({
      showCheatModal: true,
      cheatModalWordsRemaining
    })
  }

  showStatisticsModal() {
    this.setState({
      shouldShowStatisticsModal: true
    })
  }

  showInfoModal() {
    this.setState({
      shouldShowInfoModal: true
    })
  }

  showUndoHistoryModal() {
    const undosExist = this.state.undoHistory.length > 0;
    if(undosExist) {
      this.setState({
        shouldShowUndoHistoryModal: true
      })
    }
  }

  showSettingsModal() {
    this.setState({
      shouldShowSettingsModal: true
    })
  }

  hideCheatModal() {
    this.setState({
      showCheatModal: false,
      cheatModalWordsRemaining: []
    })
  }

  hideStatisticsModal() {
    this.setState({
      shouldShowStatisticsModal: false
    })
  }

  hideInfoModal() {
    this.setState({
      shouldShowInfoModal: false
    })
  }

  hideUndoHistoryModal() {
    this.setState({
      shouldShowUndoHistoryModal: false
    })
  }

  hideSettingsModal() {
    this.setState({
      shouldShowSettingsModal: false
    })
  }

  undoWord() {
    document.activeElement.blur();
    const alreadyWordled = this.state.gameResult === 'WORDLE';
    const alreadySurvived = this.state.gameResult === 'SURVIVED';
    const alreadyEliminated = this.state.gameResult === 'ELIMINATED';
    if(alreadyWordled || alreadySurvived || alreadyEliminated) {
      return;
    }
    const currentWordIndex = this.state.currentWordIndex;
    const undosRemaining = this.state.undosRemaining;
    if(undosRemaining === 0) {
      return this.setState({
        showError: true,
        errorMessage: 'You have no more Undos remaining. Please just try not to wordle.'
      })
    }
    if(currentWordIndex === 0) {
      return;
    }
    const previousWordIndex = currentWordIndex - 1;
    const guesses = JSON.parse(JSON.stringify(this.state.guesses));
    const undoHistory = JSON.parse(JSON.stringify(this.state.undoHistory));
    undoHistory.push({
      word: guesses[previousWordIndex].word,
      wordResults: guesses[previousWordIndex].wordResults
    })
    guesses[currentWordIndex] = {
      word: '',
      wordResults: [],
      submitted: false,
      isProcessing: false,
      isSuccessWord: false,
      rowIsDisqualified: false,
      numberOfValidWordsRemaining: null
    }
    guesses[previousWordIndex] = {
      word: '',
      wordResults: [],
      submitted: false,
      isProcessing: false,
      isSuccessWord: false,
      rowIsDisqualified: false,
      numberOfValidWordsRemaining: null
    }
    let validWordsRemaining = this.calculateValidWordsRemaining(previousWordIndex);
    const keyboardStatus = this.getUpdatedKeyboardStatus(guesses.slice(0,previousWordIndex));



    return this.setState({
      guesses,
      keyboardStatus,
      currentWordIndex: previousWordIndex,
      validWordsRemaining,
      undosRemaining: undosRemaining - 1,
      undoHistory,
      gameResult: 'IN PROGRESS'
    }, this.persistToLocalStorage);
  }

  selectRandomStartingWord() {
    document.activeElement.blur();
    const { currentWordIndex, undoHistory, puzzleNumber, disableKeyboard } = this.state;
    const userIsOnFirstGuess = currentWordIndex === 0 && undoHistory && undoHistory.length === 0;
    const guesses = JSON.parse(JSON.stringify(this.state.guesses))

    if(userIsOnFirstGuess && !disableKeyboard) {
      //random number between 0 and one less than the list length
      //so if the list is 2000 words long, this generates a random number between 0 and 1999
      const randomWordIndex = Math.floor(Math.random() * (wordlist2.length - 1));
      const puzzleNumberIndex = puzzleNumber - 1;
      //ensures the target word is never chosen
      const randomNonTargetWord = randomWordIndex < puzzleNumberIndex ? wordlist2[randomWordIndex] : wordlist2[randomWordIndex + 1];
      guesses[0].word = randomNonTargetWord;
      return this.setState({
        guesses,
        disableKeyboard: true,
        showError: false,
        errorMessage: ''
      }, this.handleKey.bind(this, 'Select Random Word'))
    }
  }


  render() {
    const validWordRemainingCount = this.state.validWordsRemaining.length;
    const remainingWordsColor = validWordRemainingCount > 1000 ? 'green' : validWordRemainingCount > 100 ? '#c9b458' : validWordRemainingCount > 25 ? 'orange' : 'red';
    const finalScoreStats = this.getFinalScoreStats();
    const gameIsOver = this.state.gameResult === 'WORDLE' || this.state.gameResult === 'ELIMINATED' || this.state.gameResult === 'SURVIVED';
    const showUndoHistoryLink = gameIsOver && this.state.undoHistory && this.state.undoHistory.length;

    return (
      <div className="App" tabIndex="0">
        <Toast
          errorMessage={this.state.errorMessage}
          gameResult={this.state.gameResult}
          guesses={this.state.guesses}
          validWordsRemaining={this.state.validWordsRemaining}
          currentWordIndex={this.state.currentWordIndex}
          showStatisticsModal={this.showStatisticsModal}
          targetWord={this.state.targetWord}
        />
        <Header
          showStatisticsModal={this.showStatisticsModal}
          showInfoModal={this.showInfoModal}
          showSettingsModal={this.showSettingsModal}
        />
        <div className="in-game-score">
          <div className="valid-words-remaining-container">
            <div>Valid Words Remaining</div>
            <div className="score-count" style={{'color': remainingWordsColor}}>{this.state.validWordsRemaining.length}</div>
          </div>
          <div>
            <div>Undos Remaining</div>
            <div className={showUndoHistoryLink ? "score-count show-undo-link" : "score-count"} onClick={this.showUndoHistoryModal}>{this.state.undosRemaining}</div>
          </div>
        </div>
        <GameBoard
          guesses={this.state.guesses}
          handleWordFinishedAnimating={this.handleWordFinishedAnimating}
          handleFinishSyncingFromLocalStorage={this.handleFinishSyncingFromLocalStorage}
          currentGuess={this.state.currentGuess}
          targetWord={this.state.targetWord}
          isSyncingFromLocalStorage={this.state.isSyncingFromLocalStorage}
          gameResult={this.state.gameResult}
          showCheatModal={this.showCheatModal}
        />
        <Keyboard
          currentWordIndex={this.state.currentWordIndex}
          handleOnScreenKeyBoardClick={this.handleOnScreenKeyBoardClick}
          keyboardStatus={this.state.keyboardStatus}
          undoWord={this.undoWord}
          undosRemaining={this.state.undosRemaining}
          undoHistory={this.state.undoHistory}
          gameResult={this.state.gameResult}
          showUndoHistoryModal={this.showUndoHistoryModal}
          selectRandomStartingWord={this.selectRandomStartingWord}
        />
        <GameOverMessage
          showStatisticsModal={this.showStatisticsModal}
          guesses={this.state.guesses}
          finalScoreStats={finalScoreStats}
          puzzleNumber={this.state.puzzleNumber}
          gameResult={this.state.gameResult}
          undoHistory={this.state.undoHistory}
          targetWord={this.state.targetWord}
        />
        <InfoModal
          showModal={this.state.shouldShowInfoModal}
          closeModal={this.hideInfoModal}
        />
        <CheatModal
          showModal={this.state.showCheatModal}
          validWords={this.state.cheatModalWordsRemaining}
          closeModal={this.hideCheatModal}
          targetWord={this.state.targetWord}
        />
        <GameOverModal
          showModal={this.state.shouldShowStatisticsModal}
          validWords={this.state.validWordsRemaining}
          closeModal={this.hideStatisticsModal}
          guesses={this.state.guesses}
          finalScoreStats={finalScoreStats}
          puzzleNumber={this.state.puzzleNumber}
          gameResult={this.state.gameResult}
          undoHistory={this.state.undoHistory}
        />
        <UndoHistoryModal
          showModal={this.state.shouldShowUndoHistoryModal}
          closeModal={this.hideUndoHistoryModal}
          undoHistory={this.state.undoHistory}
        />
        <SettingsModal
          showModal={this.state.shouldShowSettingsModal}
          closeModal={this.hideSettingsModal}
          hardModeEnabled={this.state.hardModeEnabled}
          toggleHardMode={this.toggleHardMode}
          disableToggleHardMode={this.state.disableToggleHardMode}
          gameResult={this.state.gameResult}
        />
      </div>
    );
  }
}

export default App;

