module.exports.getScoreToShare = function(guesses, finalScoreStats, puzzleNumber, gameResult, undoHistory) {
    let shareableScore = `Don't Wordle ${puzzleNumber}`
    let gameResultsTeaser = gameResult === 'WORDLE' ?
      ` - WORDLED\nI must admit that I Wordled!` : gameResult === 'ELIMINATED' ?
      ` - ELIMINATED\nWell technically I didn't Wordle!` : gameResult === 'SURVIVED' ?
      ` - SURVIVED\nHooray! I didn't Wordle today!` : '';

    shareableScore += gameResultsTeaser;
    for(var i = 0; i < guesses.length; i++) {
      shareableScore += '\n';
      const guess = guesses[i];
      const guessResults = guess.wordResults.length === 0 ? ['ELIMINATED', 'ELIMINATED', 'ELIMINATED', 'ELIMINATED', 'ELIMINATED'] : guess.wordResults;
      for(var j = 0; j < guessResults.length; j++) {
        const letterResult = guessResults[j];
        if(letterResult === 'HIT') {
          shareableScore += '🟩';
        } else if (letterResult === 'PARTIAL') {
          shareableScore += '🟨'
        } else if (letterResult === 'MISS') {
          shareableScore += '⬜'
        } else if (letterResult === 'ELIMINATED') {
          shareableScore += '⬛'
        }
      }
      const wordsRemaining = guess.numberOfValidWordsRemaining;
      //could be 0
      if(wordsRemaining || wordsRemaining === 0) {
        shareableScore += wordsRemaining;
      }
    }
    const undosUsed = undoHistory.length;
    shareableScore += `\nUndos used: ${undosUsed}`;


    const userSurvived = gameResult === 'SURVIVED';
    if(userSurvived) {
      const { unusedLetterCount } = finalScoreStats;
      const finalRemainingWordCount = guesses[5].numberOfValidWordsRemaining;
      shareableScore += `\n\n  ${finalRemainingWordCount} words remaining`;
      shareableScore += `\nx ${unusedLetterCount} unused letters`;
      shareableScore += `\n= ${finalRemainingWordCount * unusedLetterCount} total score`;
    }

    return shareableScore;
}

module.exports.copyScoreToClipBoard = function(score) {
  var el = document.createElement('textarea');
  el.value = score;
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);

  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      // save current contentEditable/readOnly status
      var editable = el.contentEditable;
      var readOnly = el.readOnly;

      // convert to editable with readonly to stop iOS keyboard opening
      el.contentEditable = true;
      el.readOnly = true;

      // create a selectable range
      var range = document.createRange();
      range.selectNodeContents(el);

      // select the range
      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      el.setSelectionRange(0, 999999);

      // restore contentEditable/readOnly to original state
      el.contentEditable = editable;
      el.readOnly = readOnly;
  } else {
      el.select(); 
  }

  document.execCommand('copy');
  document.body.removeChild(el);
}






