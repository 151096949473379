import React, {Component} from 'react'
import './GameOverModal.css'
import { getScoreToShare, copyScoreToClipBoard } from './../copyScore.js'

class GameOverModal extends Component {

  constructor() {
    super();
    this.state = {
      hasSharedScore: false,
      timeLeftInDay: this.getTimeLeftInDay()
    }
    this.copyShareableScore = this.copyShareableScore.bind(this);
    this.getTimeLeftInDay = this.getTimeLeftInDay.bind(this);
    this.setTimeLeftInDay = this.setTimeLeftInDay.bind(this);
  }

  componentDidMount() {
    setInterval(this.setTimeLeftInDay, 1000);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.showModal && !this.props.showModal) {
      this.setState({
        hasSharedScore: false
      })
    }
  }

  getTimeLeftInDay() {
    const currentTime = new Date();
    const upcomingMidnight = (new Date()).setHours(24,0,0,0);
    let secondsUntilMidnight = (upcomingMidnight - currentTime)/1000;
    const hoursRemaining = Math.floor(secondsUntilMidnight / 3600);
    secondsUntilMidnight -= (hoursRemaining*3600);
    const minutesRemaining = Math.floor(secondsUntilMidnight / 60);
    secondsUntilMidnight -= (minutesRemaining*60);
    secondsUntilMidnight = Math.floor(secondsUntilMidnight);
    const hoursRemainingText = hoursRemaining < 10 ? `0${hoursRemaining}` : `${hoursRemaining}`;
    const minutesRemainingText = minutesRemaining < 10 ? `0${minutesRemaining}` : `${minutesRemaining}`;
    const secondsRemainingText = secondsUntilMidnight < 10 ? `0${secondsUntilMidnight}` : `${secondsUntilMidnight}`;
    return `${hoursRemainingText}:${minutesRemainingText}:${secondsRemainingText}`;
  }

  setTimeLeftInDay() {
    const timeLeftInDay = this.getTimeLeftInDay();
    this.setState({
      timeLeftInDay
    })
  }

  copyShareableScore() {
    const {
      guesses,
      finalScoreStats,
      gameResult,
      puzzleNumber,
      undoHistory
    } = this.props;
    const shareableScore = getScoreToShare(guesses, finalScoreStats, puzzleNumber, gameResult, undoHistory);
    copyScoreToClipBoard(shareableScore)

    this.setState({
      hasSharedScore: true
    })
  }

  render() {
    const modalState = this.props.showModal ? 'visible' : 'hidden';
    const gameResult = this.props.gameResult;
    const timeLeftInDay = this.getTimeLeftInDay();
    const gameStats = JSON.parse(window.localStorage.getItem('gameStats')) || {
      currentWinStreak: 0,
      maxStreak: 0,
      totalPlayed: 0,
      totalWordles: 0,
      totalSurvivals: 0,
      totalEliminations: 0,
      lastPuzzlePlayed: null
    };
    const {
      currentWinStreak,
      maxStreak,
      totalSurvivals,
      totalEliminations,
      totalWordles,
      totalPlayed
    } = gameStats;

    const maxUniqueResult = Math.max(totalSurvivals, totalEliminations, totalWordles);
    const winPercentage = Math.round((totalSurvivals / totalPlayed) * 100) || 0; //handle NaN case
    const gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';


    return (
      <div className="overlay GameOverModal" modal-state={modalState}>
        <div className="content">
          {/*<slot></slot>*/}
          <div className="close-icon">
            <game-icon icon="close" onClick={this.props.closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </game-icon>
          </div>
          <div className="container">
            <h1>Statistics</h1>
            <div id="statistics1">
              <div className="statistic-container">
                <div className="statistic">{totalPlayed}</div>
                <div className="label">Played</div>
              </div>
              <div className="statistic-container">
                <div className="statistic">{winPercentage}</div>
                <div className="label">Win %</div>
              </div>
              <div className="statistic-container">
                <div className="statistic">{currentWinStreak}</div>
                <div className="label">Current Streak</div>
              </div>
              <div className="statistic-container">
                <div className="statistic">{maxStreak}</div>
                <div className="label">Max Streak</div>
              </div>
            </div>
            <h1>Guess Distribution</h1>
            <div id="guess-distribution">
              <div className="graph-container">
                <div className="guess">Survived</div>
                <div className="graph">
                  <div className={totalSurvivals === 0 ? 'graph-bar' : gameResult === 'SURVIVED' ? 'graph-bar align-right highlight' : 'graph-bar align-right'} style={{"width": `${Math.max((totalSurvivals/maxUniqueResult)*100,7) || 7}%`}}>
                    <div className="num-guesses">{totalSurvivals}</div>
                </div>
                </div>
              </div>
              <div className="graph-container">
                <div className="guess">Eliminated</div>
                <div className="graph">
                  <div className={totalEliminations === 0 ? 'graph-bar' : gameResult === 'ELIMINATED' ? 'graph-bar align-right highlight' : 'graph-bar align-right'} style={{"width": `${Math.max((totalEliminations/maxUniqueResult)*100,7) || 7}%`}}>
                    <div className="num-guesses">{totalEliminations}</div>
                </div>
                </div>
              </div>
              <div className="graph-container">
                <div className="guess">Wordled</div>
                  <div className="graph">
                    <div className={totalWordles === 0 ? 'graph-bar' : gameResult === 'WORDLE' ? 'graph-bar align-right highlight' : 'graph-bar align-right'} style={{'width': `${Math.max((totalWordles/maxUniqueResult)*100,7) || 7}%`}}>
                      <div className="num-guesses">{totalWordles}</div>
                  </div>
                </div>
              </div>
            </div>
            {
              gameIsOver && (
                <div className="footer">
                  <div className="countdown">
                    <h1>Next Game</h1>
                    <div id="timer">
                      <div className="statistic-container">
                        <div className="statistic timer">
                          <countdown-timer>
                              <div id="timer">{timeLeftInDay}</div>
                          </countdown-timer>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="share">
                    <button id="share-button" onClick={this.copyShareableScore}>
                      {
                        this.state.hasSharedScore ? 'Copied!' : 'Share'
                      }
                      {
                        !this.state.hasSharedScore &&
                          <game-icon icon="share">
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                                <path fill="white" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
                              </svg>
                          </game-icon>
                      }
                    </button>
                  </div>
                </div>


              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default GameOverModal;

