import React, {Component} from 'react'
import './GameBoardTile.css'

class GameBoardRow extends Component {

  constructor(props) {
      super(props);
      this.state = {
          animationState: 'empty',
          showResult: false,
          showGameOverLetters: false
      }
      this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  onAnimationEnd(e) {
    let showResult;
    let animationState;
    let showGameOverLetters;
    if(e.animationName === 'PopIn') {
        animationState = 'empty';
        showResult = false;
        showGameOverLetters = false;
    }
    if(e.animationName === 'FlipIn') {
        animationState = 'flip-out'
        showResult = true;
        showGameOverLetters = false;
    }
    if(e.animationName === 'FlipOut') {
        animationState = 'empty'
        showResult = true;
        showGameOverLetters = false;
    }
    if(e.animationName === 'FlipGameOverBegin') {
        animationState = 'flip-game-over-end'
        showResult = true;
        showGameOverLetters = true;
    }
    if(e.animationName === 'FlipGameOverEnd') {
        animationState = 'empty'
        showResult = true;
        showGameOverLetters = true;
    }
    if(e.animationName === 'Bounce') {
        animationState = 'empty'
        showResult = true;
        showGameOverLetters = false;
    }
    return this.setState({
        animationState,
        showResult,
        showGameOverLetters
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      const isNowSubmitted = !prevProps.shouldRevealLetter && this.props.shouldRevealLetter;
      const isNowSyncingFromLocalStorage = !prevProps.isSyncingFromLocalStorage && this.props.isSyncingFromLocalStorage;
      if(isNowSubmitted || isNowSyncingFromLocalStorage) {
          return this.setState({
              animationState: `flip-in`
          })
      }

      const isNowRevealed = prevState.animationState === 'flip-out' && this.state.animationState === 'empty';
      const isSyncingFromLocalStorage = this.props.isSyncingFromLocalStorage;
      if(isNowRevealed) {
          if(isSyncingFromLocalStorage) {
            return this.props.handleFinishSyncingFromLocalStorage();
          } else {
            return this.props.incrementAnimationIndex();
          }
      }

      const letterIndex = prevProps.column;
      const previousLetterState = prevProps.guess.word[letterIndex]
      const currentLetterState = this.props.guess.word[letterIndex]
      if(!previousLetterState && currentLetterState) {
          return this.setState({
              animationState: 'pop'
          })
      }

      const previouslyWasNotGameOver = !prevProps.displayGameOverLetters;
      const isNowGameOver = this.props.displayGameOverLetters;
      if(previouslyWasNotGameOver && isNowGameOver) {
          return this.setState({
              animationState: `flip-game-over-begin`
          })
      }

      const wasFinishingGameOverAnimation = prevState.animationState === 'flip-game-over-end';
      const isDoneWithGameOverAnimation = this.state.animationState === 'empty';
      if(wasFinishingGameOverAnimation && isDoneWithGameOverAnimation) {
          return this.props.handleFinishGameOverAnimation();
      }
  }

  render() {
    let guess = this.props.guess;
    let column = this.props.column;

    const currentLetter = guess.word[column];
    const isWordSubmitted = guess.submitted;
    const isMissLetter = isWordSubmitted && guess.wordResults[column] === 'MISS';
    const isSuccessLetter = isWordSubmitted && guess.wordResults[column] === 'HIT';
    const isPartialSuccessLetter = isWordSubmitted && guess.wordResults[column] === 'PARTIAL';
    const isSurvivalTile = this.props.isSurvivalTile;
    const showGameOverLetters = this.state.showGameOverLetters;
    const tileIsDisqualified = guess.rowIsDisqualified;
    //the letter is in the word
    
    let className = 'letter-box';
    if (this.state.showResult) {
        if (showGameOverLetters) {
            className += ' letter-box-death';
        }
        if (isMissLetter) {
            className += ' letter-box-miss';
        }
        if(isSuccessLetter) {
            className += ' letter-box-success'
        }
        if (isPartialSuccessLetter) {
            className += ' letter-box-partial-success';
        }
        if(isSurvivalTile) {
            className += ' letter-box-win';
        }
    }
    if(tileIsDisqualified) {
      className += ' letter-box-disqualified';
    }
    return (
        <div className={className} data-animation={this.state.animationState} key={column} onAnimationEnd={this.onAnimationEnd}>
            {currentLetter}
        </div>
    )
    
  }

}

export default GameBoardRow;
