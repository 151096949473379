import React, {Component} from 'react'
import './GameBoardRow.css'
import GameBoardTile from './GameBoardTile'

class GameBoardRow extends Component {

  constructor() {
    super();
    this.state = {
      animatingLetterIndex: 0,
      displayGameOverLetters: false
    }
    this.incrementAnimationIndex = this.incrementAnimationIndex.bind(this);
    this.handleFinishGameOverAnimation = this.handleFinishGameOverAnimation.bind(this);
    this.viewRemainingWordsAfterRow = this.viewRemainingWordsAfterRow.bind(this);
  }

  componentDidUpdate(prevProps) {
    const wordWasReset = prevProps.guess.submitted && !this.props.guess.submitted;
    if(wordWasReset) {
      return this.setState({
        animatingLetterIndex: 0
      })
    }
  }

  handleFinishGameOverAnimation() {
    this.props.handleWordFinishedAnimating(this.props.currentWordIndex);
  }

  incrementAnimationIndex() {
    const animatingLetterIndex = this.state.animatingLetterIndex + 1;
    const allLettersRevealed = animatingLetterIndex === 5;
    //hardcoding this to false for now; this was used to animate tiles to red squares
    // const isSuccessWord = false/*this.props.guess.isSuccessWord;*/
    // if(allLettersRevealed && isSuccessWord) {
    //   console.log('isSuccessWord:', isSuccessWord);
    //   return this.setState({
    //     displayGameOverLetters: true
    //   })
    // }
    if(allLettersRevealed) {
      return this.props.handleWordFinishedAnimating(this.props.currentWordIndex);
    }
    return this.setState({
      animatingLetterIndex
    })
  }

  viewRemainingWordsAfterRow(wordIndex) {
    let guess = this.props.guess;
    let gameResult = this.props.gameResult;
    let gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';
    if(gameIsOver && guess.numberOfValidWordsRemaining && guess.numberOfValidWordsRemaining <=25 && guess.numberOfValidWordsRemaining !== 0) {
      this.props.showCheatModal(wordIndex);
    }
  }

  render() {
    let columns = [0,1,2,3,4];
    let guess = this.props.guess;
    let gameResult = this.props.gameResult;
    let isProcessing = guess.isProcessing;
    let isSyncingFromLocalStorage = this.props.isSyncingFromLocalStorage;
    let animatingLetterIndex = this.state.animatingLetterIndex;
    let currentWordIndex = this.props.currentWordIndex;
    let isSurvivalTile = gameResult === 'SURVIVED' && currentWordIndex === 5;
    let gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';
    let validWordsRemainingClassName = 'valid-words-remaining-count-at-row';
    if(gameIsOver && guess.numberOfValidWordsRemaining && guess.numberOfValidWordsRemaining <=25 && guess.numberOfValidWordsRemaining !== 0) {
      validWordsRemainingClassName += ' show-link-to-words-remaining'
    }



    return (
      <div className="letter-row">
          {columns.map(column => {
              const shouldRevealLetter = isProcessing && animatingLetterIndex === column;

              return (
                <GameBoardTile
                  guess={guess}
                  column={column}
                  shouldRevealLetter={shouldRevealLetter}
                  isSyncingFromLocalStorage={isSyncingFromLocalStorage}
                  incrementAnimationIndex={this.incrementAnimationIndex}
                  handleFinishSyncingFromLocalStorage={this.props.handleFinishSyncingFromLocalStorage.bind(null, currentWordIndex, column)}
                  isSurvivalTile={isSurvivalTile}
                  displayGameOverLetters={this.state.displayGameOverLetters}
                  key={column}
                  handleFinishGameOverAnimation={this.handleFinishGameOverAnimation}
                />
              )
          })}
          {
            !isSyncingFromLocalStorage && (guess.numberOfValidWordsRemaining || guess.numberOfValidWordsRemaining === 0) && (
              <div
                className={validWordsRemainingClassName}
                onClick={this.viewRemainingWordsAfterRow.bind(this, currentWordIndex)}
              >
                {guess.numberOfValidWordsRemaining}
              </div>
            )
            
          }
      </div>
  );
    
  }

}

export default GameBoardRow;
