import './Keyboard.css'

function GameBoard(props) {
  const {
    currentWordIndex,
    handleOnScreenKeyBoardClick,
    keyboardStatus,
    undoWord,
    undosRemaining,
    undoHistory,
    gameResult,
    showUndoHistoryModal,
    selectRandomStartingWord } = props;
  const gameIsInTempEliminatedState = gameResult === 'TEMPORARILY ELIMINATED';
  const userIsOnFirstGuess = currentWordIndex === 0 && undoHistory && undoHistory.length === 0;
  const gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';

  const getLetterClassName = function(letter) {
      let defaultClassName = 'keyboard-button';
      const successLetter = keyboardStatus[letter] === 'HIT';
      const partialSuccessLetter = keyboardStatus[letter] === 'PARTIAL';
      const failedLetter = keyboardStatus[letter] === 'MISS';

      if(gameIsInTempEliminatedState) {
        defaultClassName += ' keyboard-button-miss';
      } else {
        if(successLetter) {
            defaultClassName += ' keyboard-button-success'
        }

        if(partialSuccessLetter) {
            defaultClassName += ' keyboard-button-partial-success'
        }

        if(failedLetter) {
            defaultClassName += ' keyboard-button-miss'
        }

      }


      return defaultClassName;
  }

  const getEnterClassName = function() {
    let defaultClassName = 'keyboard-button';
    if(gameIsInTempEliminatedState) {
      defaultClassName += ' keyboard-button-miss'
    }
    return defaultClassName;
  }

  const getDeleteClassName = function() {
    let defaultClassName = 'keyboard-button';
    if(gameIsInTempEliminatedState) {
      defaultClassName += ' keyboard-button-miss'
    }
    return defaultClassName;
  }

  const getUndoButtonClassName = function() {
    let defaultClassName = 'keyboard-button';
    if(undosRemaining === 0) {
      defaultClassName += ' keyboard-button-miss'
    }
    return defaultClassName;
  }

  const getViewPriorUndosButtonClassName = function() {
    let defaultClassName = 'keyboard-button';
    if(undoHistory.length === 0 || gameIsInTempEliminatedState) {
      defaultClassName += ' keyboard-button-miss'
    }
    return defaultClassName;
  }

  return (
    !gameIsOver && (
    <div id="keyboard-cont">
        <div className="special-buttons">
        {
          userIsOnFirstGuess ? (
              <button className="keyboard-button" id="random-starting-word" onClick={selectRandomStartingWord}>Random Starting Word</button>
          ) : [
              <button className={getViewPriorUndosButtonClassName()} id="show-undo-results" onClick={showUndoHistoryModal}>View Prior Undos</button>,
              <button className={getUndoButtonClassName()} id="undo-button" onClick={undoWord}>Undo Prior Word</button>
          ]
        }
        </div>
        <div className="first-row">
            <button className={getLetterClassName('Q')} onClick={handleOnScreenKeyBoardClick}>q</button>
            <button className={getLetterClassName('W')} onClick={handleOnScreenKeyBoardClick}>w</button>
            <button className={getLetterClassName('E')} onClick={handleOnScreenKeyBoardClick}>e</button>
            <button className={getLetterClassName('R')} onClick={handleOnScreenKeyBoardClick}>r</button>
            <button className={getLetterClassName('T')} onClick={handleOnScreenKeyBoardClick}>t</button>
            <button className={getLetterClassName('Y')} onClick={handleOnScreenKeyBoardClick}>y</button>
            <button className={getLetterClassName('U')} onClick={handleOnScreenKeyBoardClick}>u</button>
            <button className={getLetterClassName('I')} onClick={handleOnScreenKeyBoardClick}>i</button>
            <button className={getLetterClassName('O')} onClick={handleOnScreenKeyBoardClick}>o</button>
            <button className={getLetterClassName('P')} onClick={handleOnScreenKeyBoardClick}>p</button>
        </div>
        <div className="second-row">
            <button className={getLetterClassName('A')} onClick={handleOnScreenKeyBoardClick}>a</button>
            <button className={getLetterClassName('S')} onClick={handleOnScreenKeyBoardClick}>s</button>
            <button className={getLetterClassName('D')} onClick={handleOnScreenKeyBoardClick}>d</button>
            <button className={getLetterClassName('F')} onClick={handleOnScreenKeyBoardClick}>f</button>
            <button className={getLetterClassName('G')} onClick={handleOnScreenKeyBoardClick}>g</button>
            <button className={getLetterClassName('H')} onClick={handleOnScreenKeyBoardClick}>h</button>
            <button className={getLetterClassName('J')} onClick={handleOnScreenKeyBoardClick}>j</button>
            <button className={getLetterClassName('K')} onClick={handleOnScreenKeyBoardClick}>k</button>
            <button className={getLetterClassName('L')} onClick={handleOnScreenKeyBoardClick}>l</button>
        </div>
        <div className="third-row">
            <button className={getEnterClassName()} onClick={handleOnScreenKeyBoardClick}>Enter</button>
            <button className={getLetterClassName('Z')} onClick={handleOnScreenKeyBoardClick}>z</button>
            <button className={getLetterClassName('X')} onClick={handleOnScreenKeyBoardClick}>x</button>
            <button className={getLetterClassName('C')} onClick={handleOnScreenKeyBoardClick}>c</button>
            <button className={getLetterClassName('V')} onClick={handleOnScreenKeyBoardClick}>v</button>
            <button className={getLetterClassName('B')} onClick={handleOnScreenKeyBoardClick}>b</button>
            <button className={getLetterClassName('N')} onClick={handleOnScreenKeyBoardClick}>n</button>
            <button className={getLetterClassName('M')} onClick={handleOnScreenKeyBoardClick}>m</button>
            <button className={getDeleteClassName()} onClick={(event) => {handleOnScreenKeyBoardClick(event, 'DEL')}}>
              <svg className="delete-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="black" d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"></path>
              </svg>
            </button>
        </div>
    </div>
    )
  );
}

export default GameBoard;
