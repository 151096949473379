import React, {Component} from 'react'
import './GameOverMessage.css'
import { getScoreToShare, copyScoreToClipBoard } from './../copyScore.js'

class GameOverMessage extends Component {

  constructor() {
    super();
    this.state = {
      hasSharedScore: false
    }
    this.copyShareableScore = this.copyShareableScore.bind(this);
  }

  copyShareableScore() {
    const {
      guesses,
      finalScoreStats,
      gameResult,
      puzzleNumber,
      undoHistory
    } = this.props;
    const shareableScore = getScoreToShare(guesses, finalScoreStats, puzzleNumber, gameResult, undoHistory);
    const self = this;
    copyScoreToClipBoard(shareableScore)

    this.setState({
      hasSharedScore: true
    }, function() {
      setTimeout(function() {
        self.setState({
          hasSharedScore: false
        })
      }, 3000)
    })
  }

  render() {
    const gameResult = this.props.gameResult;
    const gameIsOver = gameResult === 'WORDLE' || gameResult === 'ELIMINATED' || gameResult === 'SURVIVED';
    const wordleMessage = (<div>Oh no! You accidentally Wordled!</div>);
    const eliminatedMessage = [
      <div>{`Oh no! You have been eliminated!`}</div>,
      <div>{`You don't have enough words left to use!`}</div>,
      <div>{`Today's word was ${this.props.targetWord}.`}</div>
    ];
    const survivalMessage = [
      <div>{`Well done! You managed not to Wordle!`}</div>,
      <div>{`Today's word was ${this.props.targetWord}.`}</div>,
    ];
    const messageText = gameResult === 'SURVIVED' ?
      survivalMessage : gameResult === 'WORDLE' ?
      wordleMessage : gameResult === 'ELIMINATED' ?
      eliminatedMessage :  null;

    return (
      gameIsOver && (
        <div className="game-over-section">
          <div className="game-over-message">
            {messageText}
          </div>
          <div className="share-button-container">
            <div>
                <button id="stats-button-home-page" onClick={this.props.showStatisticsModal}>
                  Stats
                  <game-icon icon="share">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path fill="white" d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z"></path>
                      </svg>
                  </game-icon>
                </button>
            </div>
            <div>
              <button id="share-button-home-page" onClick={this.copyShareableScore}>
                {
                  this.state.hasSharedScore ? 'Copied!' : 'Share'
                }
                {
                  !this.state.hasSharedScore &&
                    <game-icon icon="share">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                          <path fill="white" d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path>
                        </svg>
                    </game-icon>
                }
              </button>
            </div>
          </div>
        </div>
        
      )
    );
  }
}
export default GameOverMessage;

