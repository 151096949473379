import React, {Component} from 'react'
import './SettingsModal.css'

class SettingsModal extends Component {

  render() {
    const modalState = this.props.showModal ? 'visible' : 'hidden';
    const gameResult = this.props.gameResult;
    const disableToggleHardMode = this.props.disableToggleHardMode;

    return (
      <div className="settings-overlay" modal-state={modalState}>
        <div className="settings-overlay-content">
          {/*<slot></slot>*/}
          <div className="close-icon">
            <game-icon icon="close" onClick={this.props.closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </game-icon>
          </div>
          <h3 style={{textAlign: "center"}}>Settings</h3>
          <div className="sections">
            <section>
              <div className="setting">
                <div className="text">
                  <div className="title">Hard Mode</div>
                  <div className="description">Limited to only two UNDOs per round</div>
                </div>
                <div className="control">
                  <game-switch id="hard-mode" name="hard-mode" checked={this.props.hardModeEnabled} disabled={disableToggleHardMode}>
                    <div className="container">
                    <label><slot></slot></label>
                    <div className="switch" onClick={this.props.toggleHardMode}>
                      <span className="knob"></span></div>
                    </div>
                  </game-switch>
                </div>
              </div>
            </section>

            <section>
              <div className="setting">
                <div className="text">
                  <div className="title">Community</div>
                </div>
                <div className="control">
                  <a href="https://twitter.com/dontwordle" target="blank" title="@dontwordle">Twitter</a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsModal;

