import React, {Component} from 'react'
import './UndoHistoryModal.css'

class UndoHistoryModal extends Component {

  render() {
    const modalState = this.props.showModal ? 'visible' : 'hidden';
    const letterBoxClassName = {
      HIT: 'undo-word-result-letter undo-letter-box-success',
      PARTIAL: 'undo-word-result-letter undo-letter-box-partial-success',
      MISS: 'undo-word-result-letter undo-letter-box-miss'
    }

    return (
      <div className="overlay UndoHistoryModal" modal-state={modalState}>
        <div className="content">
          {/*<slot></slot>*/}
          <div className="close-icon">
            <game-icon icon="close" onClick={this.props.closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </game-icon>
          </div>
          <h3 style={{textAlign: "center"}}>Undos Used</h3>
          <ol>
            {
              this.props.undoHistory.map((guess, undoCount) => {
                const guessRow = guess.wordResults.map((result, index) => {
                  return (
                    <div className={letterBoxClassName[result]} key={index}>{guess.word[index]}</div>
                  )
                })
                return (
                  <li className="undo-word-list" key={undoCount}><div className="undo-word-result">{guessRow}</div></li>
                )
              })
            }
          </ol>
        </div>
      </div>
    );
  }
}

export default UndoHistoryModal;

