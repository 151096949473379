import React, {Component} from 'react'
import GameBoardRow from './GameBoardRow'
import './GameBoard.css'

class GameBoard extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let rows = [0,1,2,3,4,5];
    let guesses = this.props.guesses;

    return (
      <div id="game-board">
        {rows.map(row => {
            const guess = guesses[row];
            return (
              <GameBoardRow
                guess={guess}
                key={row}
                currentWordIndex={row}
                handleWordFinishedAnimating={this.props.handleWordFinishedAnimating}
                handleFinishSyncingFromLocalStorage={this.props.handleFinishSyncingFromLocalStorage}
                isSyncingFromLocalStorage={this.props.isSyncingFromLocalStorage}
                gameResult={this.props.gameResult}
                showCheatModal={this.props.showCheatModal}
              />
            )
        })}
      </div>
  );
    
  }

}

export default GameBoard;
