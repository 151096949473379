import React, {Component} from 'react'
import './CheatModal.css'

class CheatModal extends Component {

  render() {
    const modalState = this.props.showModal ? 'visible' : 'hidden';
    const letterBoxClassName = {
      HIT: 'valid-word-remaining-result-letter valid-letter-box-success',
      PARTIAL: 'valid-word-remaining-result-letter valid-letter-box-partial-success',
      MISS: 'valid-word-remaining-result-letter valid-letter-box-miss'
    }
    const validWordsRemaining = JSON.parse(JSON.stringify(this.props.validWords));
    validWordsRemaining.sort((word1, word2) => {
      if ( word1.word < word2.word ){
        return -1;
      }
      if ( word1.word > word2.word ){
        return 1;
      }
      return 0;
    })

    return (
      <div className="overlay CheatModal" modal-state={modalState}>
        <div className="content">
          {/*<slot></slot>*/}
          <div className="close-icon">
            <game-icon icon="close" onClick={this.props.closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path fill="var(--color-tone-1)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </game-icon>
          </div>
          <h3 style={{textAlign: "center"}}>Valid Words Remaining</h3>
          <ol>
            {
              validWordsRemaining.map((guess, validWordIndex) => {
                const guessRow = guess.wordResults.map((result, index) => {
                  return (
                    <div className={letterBoxClassName[result]} key={index}>{guess.word[index]}</div>
                  )
                })
                return (
                  <li className="valid-word-remaining-list" key={validWordIndex}><div className="valid-word-remaining-result">{guessRow}</div></li>
                )
              })
            }
          </ol>
        </div>
      </div>
    );
  }
}

export default CheatModal;

